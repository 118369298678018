import React from "react";

function Paginacion({
  yiyersPerPage,
  currentPage,
  setCurrentPage,
  totalYiyers,
}) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalYiyers / yiyersPerPage); i++) {
    pageNumbers.push(i);
  }

  const onPreviousButton = () => {
    setCurrentPage(currentPage - 1)
  }

  const onNextButton = () => {
    setCurrentPage(currentPage + 1)
  }

  const onNumberButton = (n) => {
    setCurrentPage(n)
  }

  


  return (
    <nav aria-label="Page navigation example">
      <ul className="inline-flex -space-x-px text-sm">
        <li>
          <a
            /* href="#" */
            className={`flex items-center cursor-pointer justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 ${currentPage <= 1 ? 'opacity-30 cursor-default' : ''}`}
            onClick={currentPage <= 1 ? (event) => event.preventDefault() : onPreviousButton}
          >
            Anterior
          </a>
        </li>
        {/* Números dinámicos */}
        {pageNumbers.map((noPage) => (
          <li key={noPage}>
            <a
              onClick={() => onNumberButton(noPage)}
              className={`${currentPage === noPage ? 'z-10 flex cursor-pointer items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700' : 'flex cursor-pointer items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'} `}
            >
              {noPage}
            </a>
          </li>
        ))}

        <li>
          <a
            /* href="#" */
            className={`flex items-center cursor-pointer justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 ${currentPage >= pageNumbers.length ? 'opacity-30 cursor-default' : ''}`}
            onClick={currentPage >= pageNumbers.length ? (event) => event.preventDefault() : onNextButton }
          >
            Siguiente
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Paginacion;
