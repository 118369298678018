import React from "react";
import CardValores from "./CardValores";

function ValoresHome() {
  return (
    <section className="bg-Bunting-3 flex flex-col justify-center items-center w-screen py-10  space-y-5 sm:space-x-8 text-center">
      <h1 className=" text-3xl font-extrabold max-w-prose str">
        Nuestros valores
      </h1>
      <p>
        El interés genuino por crear espacios de colaboración y aprendizaje a
        través de conversaciones de valor.
      </p>
      <article className="flex flex-wrap justify-center align-middle gap-4">
        <CardValores
          strokeColor="stroke-Flamingo-0"
          bgColor="bg-Flamingo-3"
          titulo="Empatía"
          texto="Creemos que la clave de toda conversación de valor es la empatía, en donde ambas personas intentan comprender y ponersele en la situación del otro."
        />
        <CardValores
          strokeColor="stroke-Shark-0"
          bgColor="bg-White"
          titulo="Confianza"
          texto="Toda conversación efectiva se basa en crear un ambiente de confianza mutua, en donde ambas personas puedan expresar sus opiniones sin prejuicios."
        />
        <CardValores
          strokeColor="stroke-Leopard-0"
          bgColor="bg-Leopard-3"
          titulo="Autenticidad"
          texto="No nos interesan las pretenciones, queremos que todos los miembros de Yiyaz, tanto usuarios como Yiyers, sean genuinos, auténticos y fieles a ellos mismos."
        />
        {/* <CardValores
          strokeColor="stroke-Bunting-0"
          bgColor="bg-White"
          titulo="Characteristic"
          texto="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        /> */}
      </article>
    </section>
  );
}

export default ValoresHome;
