import React from 'react'
import SignUpForm from '../components/comun/SignUpForm'

function Registro() {
  return (
    <>
    <SignUpForm />
    </>
  )
}

export default Registro