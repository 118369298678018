import React, { useContext, useEffect, useState } from "react";
import { Avatar, Rating, Button, Dialog } from "@material-tailwind/react";
import { ContextUser } from "../../context/ContextUser";
import { MdOutlinePlace, MdOutlineEmail, MdOutlineMessage } from "react-icons/md";
import { CiLinkedin } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import EditarAgendaYiyer from "../yiyer/EditarAgendaYiyer";
import imagesApp from "../../assets/images/staticimgs";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import config from "../../util/config";
import MiDisponibilidad from "../yiyer/MiDisponibilidadProfileYiyer";
import EditProfileIconButton from "../yiyer/EditProfileIconButton";

function MyProfileYiyer() {
  const { yiyerData, setYiyerData } = useContext(ContextUser);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { token, logout } = useAuth();

  const handleOpen = () => {
    setOpen(!open);
  }

 
  useEffect(() => {
    if (!token) {
      // Si no hay un token, redirige al usuario a la página de inicio de sesión.
      navigate("/");
      return;
    }
    if (token) {
      axios
        .get(`${config.URL_HOST}/miperfil`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setYiyerData(response.data);
        })
        .catch((error) => {
          console.error("Error al obtener los datos del usuario:", error);

          // Si el token es inválido o ha caducado, redirige al usuario a la página de login.
          logout()
          navigate("/login");
        });
    }
  }, [token, navigate]);

  const replaceImageUrl = (url) => {
    return url.replace("/home/yiyazcom/public_html", "https://www.yiyaz.com");
  };

  return (
    <section className="flex flex-col sm:flex-row justify-center align-middle w-full bg-gray-50 p-2 font-outfit ">
      
      {yiyerData && (
        <>
          <div className="relative flex flex-col w-full lg:w-3/5 p-5 bg-white rounded-xl shadow-xl mx-auto min-w-[340px] gap-3 my-6">
          <EditProfileIconButton />
            {/* HEADER */}
            <div className="flex flex-row gap-5 ">
              {yiyerData.imgurl ? (
                <Avatar
                  src={replaceImageUrl(yiyerData.imgurl)}
                  alt="avatar"
                  size="xxl"
                  loading="lazy"
                  onError={(e) => {
                    e.target.src = imagesApp.noImg;
                  }}
                />
              ) : (
                <img
                  src={imagesApp.noImg}
                  alt="avatar"
                  className="w-20 h-20 rounded-full"
                />
              )}
              <div className="flex flex-col justify-center">
                <h1 className="font-black text-xl">
                  {yiyerData.nombre} {yiyerData.apellido}
                </h1>
                <p className="flex flex-row items-center text-gray-600 text-sm"><MdOutlineEmail  className="mr-1" /> {yiyerData.email}</p>
                {/* <p className="flex flex-row items-center text-gray-600 text-sm"><MdOutlineMessage  className="mr-1" /> {yiyerData.categoria}</p> */}
                { yiyerData.linkedin ?<p className="flex flex-row items-center text-sm text-blue-gray-700">
                  <CiLinkedin className="mr-1" /> {yiyerData.linkedin}
                </p> : null}
                <p className="flex flex-row items-center text-sm text-blue-gray-700">
                  <MdOutlinePlace className="mr-1" /> {yiyerData.pais}
                </p>
                <Rating readonly />

                
              </div>

              
            </div>
            {/* END HEADER */}

            <div className="flex flex-col w-full mt-2 py-5 font-outfit text-sm border-b border-gray-200">
            {yiyerData.categoria ? (
  <span className="flex flex-col justify-start items-start gap-1 mb-3 pb-2">
    <h1 className="flex flex-row gap-2 items-center text-base font-bold mb-5"><div className="bg-orange-400 w-3 h-3"></div>Temas de interés</h1>
    <p className="text-md font-medium text-sm text-gray-600">
      
      {yiyerData.categoria
        .slice(1, -1)
        .split('","')
        .map((item, index) => (
          <span key={index} className="border border-orange-400 rounded-full px-2 py-1 mt-2 mr-2 text-xs text-orange-400">{item.replace(/"/g, '')}</span>
        ))}
    </p>
  </span>
) : null}
            <h1 className="flex flex-row gap-2 items-center text-base font-bold mb-5"><div className="bg-orange-400 w-3 h-3"></div>Introducción</h1>
              <p className="text-gray-700">{yiyerData.intro}</p>
            </div>

            <div className="flex flex-col w-full py-2 font-outfit text-sm border-b border-gray-200">
              <h1 className="flex flex-row gap-2 items-center text-base font-bold mb-5"><div className="bg-orange-400 w-3 h-3"></div>Experiencia</h1>
              <p className="text-gray-700">{yiyerData.experiencia}</p>
            </div>



            <div className="flex flex-col border border-blue-gray-100 rounded-lg p-3 gap-3 mt-5 bg-blue-gray-50">
              <h1 className="text-base font-bold mb-2">Disponibilidad</h1>
              <div className="flex flex-row justify-center gap-3 font-outfit">
                <MiDisponibilidad userId={ yiyerData.id } handleOpen={handleOpen}  />
              </div>
              <Button className="w-3/4 md:w-6/12 mx-auto mt-4 bg-gray-900 text-white hover:bg-gray-700" onClick={handleOpen} variant="outlined">
                Editar Disponibilidad
              </Button>
            </div>
           {/*  <Button onClick={() => navigate("/editprofile")}>Editar</Button> */}
          </div>
          <Dialog open={open} onClose={handleOpen}>
            <EditarAgendaYiyer userId={yiyerData.id} handleOpen={handleOpen} />
          </Dialog>
        </>
      )}
    </section>
  );
}

export default MyProfileYiyer;
