import React, { useState } from "react";
import { Avatar, Rating, Button } from "@material-tailwind/react";
import { HiAcademicCap, HiMiniChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa6";
import { Link } from "react-router-dom";
import imagesApp from "../../assets/images/staticimgs";

const CardYiyersHorizontal = ({ id, name, lastname, picture, intro, profesion, horarios, categoria, linkedin, experiencia }) => {
  const [isIntroShown, setIsIntroShown] = useState(false);
  const [isExperienciaShown, setIsExperienciaShown] = useState(false);

  const toggleIsIntroShown = () => setIsIntroShown(!isIntroShown);
  const toggleIsExperienciaShown = () => setIsExperienciaShown(!isExperienciaShown);

  const introToShow = intro ? (isIntroShown ? intro : `${intro.substring(0, 100)}...`) : '';
  const experienciaToShow = experiencia ? (isExperienciaShown ? experiencia : `${experiencia.substring(0, 100)}...`) : '';
  const storedUserData = JSON.parse(localStorage.getItem("userData") || '{}');
  
  // Filtrar la fecha más cercana en el futuro
  const fechaActual = new Date();
  const fechaMasCercana = horarios && horarios.length > 0
    ? horarios.reduce((closestDateTime, horario) => {
        if (horario.date && horario.start_time) {
          const dateOnly = horario.date.split('T')[0];
          const timeOnly = horario.start_time;
          const dateTimeString = `${dateOnly}T${timeOnly}:00.000Z`; // Añade los segundos y milisegundos
          const currentDateTime = new Date(dateTimeString);

          // Verificar si currentDateTime es una fecha válida y está en el futuro
          if (!isNaN(currentDateTime.getTime()) && currentDateTime > fechaActual) {
            // Verificar si closestDateTime es null o currentDateTime es más cercano
            if (!closestDateTime || currentDateTime < closestDateTime) {
              return currentDateTime;
            }
          } else {
            console.error(`Fecha inválida o pasada para ${JSON.stringify(horario)}`);
          }
        } else {
          console.error(`Fecha o start_time indefinidos para ${JSON.stringify(horario)}`);
        }

        return closestDateTime;
      }, null)
    : null;

  // Obtener el horario correspondiente a la fecha más cercana
  const horarioFechaMasCercana = fechaMasCercana
    ? horarios.find(h => h.date.split('T')[0] === fechaMasCercana.toISOString().split('T')[0])
    : null;

  /* console.log(horarioFechaMasCercana) */

  const replaceImageUrl = (url) => {
    return url.replace("/home/yiyazcom/public_html", "https://www.yiyaz.com");
  };



  return (
    <div className="flex flex-col sm:flex-row sm:justify-around w-full p-4 shadow-md rounded-xl bg-white font-outfit">
      {/* Area Datos */}
      <div className="flex flex-col gap-2 w-full sm:w-9/12 md:w-8/12">
        <div className="flex flex-row gap-5">
          {picture ? (
            <Avatar
              src={replaceImageUrl(picture)}
              alt="avatar"
              size="xxl"
              loading="lazy"
              onError={(e) => {
                e.target.src = imagesApp.noImg;
              }}
            />
          ) : (
            <img
              src={imagesApp.noImg}
              alt="avatar"
              className="w-20 h-20 rounded-full"
            />
          )}
          <div className="flex flex-col w-9/12 gap-1">
            <h1 className="text-xl font-bold text-left">
              {name + " " + lastname}
            </h1>
            <span className="flex flex-row justify-start items-start gap-1">
              <span className="text-orange-400"><HiAcademicCap size={16} /></span>
              <p className="text-md font-medium text-sm text-gray-600">
                {profesion}
              </p>
            </span>

            
            
            {linkedin ? <span className="flex flex-row justify-start items-start gap-1">
              <span className="text-orange-400"><FaLinkedin  size={16} /></span>
              <p className="text-md font-medium text-sm text-gray-600">
                <a href={linkedin} target="blank">{linkedin}</a>
              </p>
            </span> : null}
            <Rating value={0} unratedColor="gray" ratedColor="amber" readonly />
          </div>
        </div>

        {categoria ? (
  <span className="flex flex-col justify-start items-start gap-1 mb-3 pb-2">
    <h1 className="text-sm font-bold text leading-tight mb-2">Temas de interés:{" "}</h1>
    <p className="text-md font-medium text-sm text-gray-600">
      
      {categoria
        .slice(1, -1)
        .split('","')
        .map((item, index) => (
          <span key={index} className="border border-orange-400 rounded-full px-2 py-1 mt-2 mr-2 text-xs text-orange-400">{item.replace(/"/g, '')}</span>
        ))}
    </p>
  </span>
) : null}
        
        {intro ? <h1 className="text-sm font-bold mb-0 text leading-tight">Introducción</h1> : ''}
        <p className="text-sm text-blue-gray-300 pt-0 mt-0 border-b pb-2">
          {introToShow}
          <button className="border p-0.5 border-gray-400 rounded-md ml-2 text-xs text-gray-400 hover:bg-gray-400 hover:text-orange-50" onClick={toggleIsIntroShown}>
          {intro ? (isIntroShown  ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />) : null}
        </button>
        </p>

        {experiencia ? <h1 className="text-sm font-bold mb-0 leading-tight">Experiencia</h1> : ''}
        <p className=" text-sm text-blue-gray-300">
          {experienciaToShow}
          {experiencia ? <button className="border p-0.5 border-gray-400 rounded-md ml-2 text-xs text-gray-400 hover:bg-gray-400 hover:text-orange-50" onClick={toggleIsExperienciaShown}>
          {experiencia ? (isExperienciaShown  ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />): null}
        </button> : ''}
        </p>
        
        
        {fechaMasCercana && horarioFechaMasCercana && (
          <p className="text-sm font-bold font-outfit text-gray-600">
            Próxima sesión: <span className="p-1 bg-Shark-2 border border-Shark-0 rounded-md font-light text-white ml-3">{fechaMasCercana.toLocaleDateString()} - {horarioFechaMasCercana.start_time} a {horarioFechaMasCercana.end_time}</span>
          </p>
        )}
      </div>

      {/* Area botones */}
      <div className="flex flex-row sm:flex-col w-full sm:w-3/12 md:w-3/12 p-5 gap-4 justify-center sm:justify-center">
        <Link to={`/yiyersprofile/${id}`}>
          <Button variant="outlined" className="w-12/12 md:w-10/12 lg:w-10/12 xl:w-8/12 mx-auto">Perfil</Button>
        </Link>
        {storedUserData && storedUserData.rol === "yiyu" && (
        <Link to={`/agendaryiyer/${id}`}>
        <Button className="w-12/12 sm:w-12/12 md:w-10/12 lg:w-10/12 xl:w-8/12 mx-auto">Agendar</Button>
        </Link>
        )}
      </div>
    </div>
  );
};

export default CardYiyersHorizontal;
