import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import MasterClasses from "./pages/MasterClasses";
import AcercaDe from "./pages/AcercaDe";
import ComoFunciona from "./pages/ComoFunciona";
import Comunidad from "./pages/Comunidad";
import Login from "./pages/Login";
import Registro from "./pages/Registro";
import { useState } from "react";
import { ContextUser } from "./context/ContextUser";
import YiyazFooter from "./components/comun/YiyazFooter";
import MyProfileYiyer from "./components/comun/MyProfileYiyer";
import EditarMiPerfilYiyer from "./components/comun/EditarMiPerfilYiyer";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoutes from "./util/ProtectedRoutes";
import YiyersProfile from "./components/yiyer/YiyersProfile";
import MyProfileYiyu from "./components/Yiyus/MyProfileYiyu";
import SignUpFormYiyus from "./components/Yiyus/SignUpFormYiyus";
import AgendarYiyerv2 from "./pages/AgendarYiyer_v2";
import AgendaYiyu from "./components/Yiyus/AgendaYiyu";
import { app } from './util/firebase'
import "./styles/App.css"
import AgendaYiyer from "./components/yiyer/AgendaYiyer";




function App() {
  const [usersData, setUsersData] = useState([]);
  const [yiyerData, setYiyerData] = useState([]);

  return (
    <div className="overflow-x-hidden h-full pt-[102px]">
      <AuthProvider>
      <ContextUser.Provider
        value={{ usersData, setUsersData, yiyerData, setYiyerData }}
      >
        
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/comunidad" element={<Comunidad />} />
            <Route path="/masterclasses" element={<MasterClasses />} />
            <Route path="/about" element={<AcercaDe />} />
            <Route path="/comofunciona" element={<ComoFunciona />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registro" element={<Registro />} />
            <Route path="/yiyersprofile/:id" element={<YiyersProfile />} />
            <Route path="/miperfil" element={<MyProfileYiyer />} />
            <Route path="/yiyuprofile" element={<MyProfileYiyu />} />
            <Route path="/editprofile" element={<EditarMiPerfilYiyer />} />
            <Route path="/registroyiyu" element={<SignUpFormYiyus />} />
            <Route path="/agendaryiyer/:id" element={<AgendarYiyerv2 />} />
            <Route path="/agendayiyu/:id" element={<AgendaYiyu />} />
            <Route path="/agendayiyer/:id" element={<AgendaYiyer />} />
            
          </Routes>
          <YiyazFooter />
        </BrowserRouter>
        
      </ContextUser.Provider>
      </AuthProvider>
    </div> 
  );
}

export default App;
