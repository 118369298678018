import React from 'react'
import YifyLogo from "../../assets/images/Logo.svg";
import { Footer } from 'flowbite-react';
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs';

function YiyazFooter() {
  return (
    
    <Footer container className='bg-Black rounded-none'>
      <div className="w-full">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <Footer.Brand
              alt="Yiyaz Logo"
              href="/"
              src={YifyLogo}
            />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-4 sm:gap-6">
            <div>
              <Footer.Title title="Usuarios" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">
                  Perfil
                </Footer.Link>
                <Footer.Link href="#">
                  Métodos de pago
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Yiyers" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">
                  Perfil
                </Footer.Link>
                <Footer.Link href="#">
                  Métodos de pago
                </Footer.Link>
                <Footer.Link href="#">
                  Inscripción
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Info" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">
                  Suscripción
                </Footer.Link>
                <Footer.Link href="#">
                  FAQ
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Contacto" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">
                  (+56 2) 0000 0000
                </Footer.Link>
                <Footer.Link href="#">
                  contacto@yiyaz.com
                </Footer.Link>
                <Footer.Link href="#">
                  Dirección
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright
            by="Yiyaz™"
            href="/"
            year={new Date().getFullYear() }
          />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon
              href="#"
              icon={BsFacebook}
            />
            <Footer.Icon
              href="#"
              icon={BsInstagram}
            />
            <Footer.Icon
              href="#"
              icon={BsTwitter}
            />
          </div>
        </div>
      </div>
    </Footer>
  )
}

export default YiyazFooter