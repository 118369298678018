import React, { useContext, useEffect, useState } from "react";
import CardYiyersHorizontal from "../components/comun/CardYiyersHorizontal";
import { ContextUser } from "../context/ContextUser";
import Paginacion from "../components/comun/Paginacion";
import axios from "axios";
import config from "../util/config";
import { Input, Select, Option, Button } from "@material-tailwind/react";

const Comunidad = () => {
  const [searchValue, setSearchValue] = useState("");
  const [yiyersPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [timeRange, setTimeRange] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const { usersData, setUsersData } = useContext(ContextUser);
  const totalYiyers = usersData.length;

  const lastIndex = currentPage * yiyersPerPage;
  const firstIndex = lastIndex - yiyersPerPage;

  const handleSearch = (event) => {
    event.preventDefault();

    const [start, end] = timeRange.split(' - ').map(time => Number(time.replace(':', '.')));

    const filteredUsers = usersData.filter((user) => {
      const validHorarios = user.horarios.filter(horario => {
        const [horarioStart, horarioEnd] = [horario.start_time, horario.end_time].map(time => Number(time.replace(':', '.')));
        return horarioStart >= start && horarioEnd <= end;
      });

      return validHorarios.length > 0;
    });

    setUsersData(filteredUsers);
  };

  const resetFilters = () => {
    setSearchValue("");
    setTimeRange("");
    setSelectedCategory("");
    fetchUsersData();
  };

  const fetchUsersData = () => {
    axios
      .get(`${config.URL_HOST}/yiyers`)
      .then((result) => {
        const users = result.data;
        axios
          .get(`${config.URL_HOST}/gethorarios`)
          .then((result) => {
            const horariosData = result.data;
            const usersWithHorarios = users.map(user => {
              const userHorarios = horariosData.filter(horario => Number(horario.userid) === Number(user.id));
              return {...user, horarios: userHorarios.length > 0 ? userHorarios : null};
            });
            setUsersData(usersWithHorarios);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchUsersData();
  }, [setUsersData]);

  const parseCategoryString = (categoryString) => {
    // Eliminar las llaves y dividir por comas
    return categoryString.replace(/[{}"]/g, '').split(',').map(cat => cat.trim());
  };

  return (
    <section className="bg-blue-gray-50 relative">
      <div>
        <form
          className="flex flex-col sm:flex-row p-5 w-full gap-5 bg-Gray-1 static"
          onSubmit={handleSearch}
        >
          <Input
            label="Buscar Yiyers..."
            value={searchValue}
            className="bg-white"
            onChange={(e) => setSearchValue(e.target.value)}
          />

          <Select label="Horario" value={timeRange} onChange={(value) => setTimeRange(value)}>
            <Option>09:00 - 12:00</Option>
            <Option>12:00 - 15:00</Option>
            <Option>15:00 - 18:00</Option>
            <Option>18:00 - 21:00</Option>
            <Option>21:00 - 00:00</Option>
            <Option>00:00 - 03:00</Option>
            <Option>03:00 - 06:00</Option>
            <Option>06:00 - 09:00</Option>
          </Select>

          <Select
            variant="outlined"
            label="Tema de interés"
            name="categoria"
            value={selectedCategory}
            onChange={(value) => setSelectedCategory(value)}
          >
            <Option value="Tecnología">Tecnología</Option>
            <Option value="Comunicación">Comunicación</Option>
            <Option value="Liderazgo">Liderazgo</Option>
            <Option value="Relatos - Story Telling">Relatos - Story Telling</Option>
            <Option value="Hablar en publico">Hablar en publico</Option>
            <Option value="Transformacion Organizacional">Transformacion Organizacional</Option>
            <Option value="Sostenibilidad">Sostenibilidad</Option>
            <Option value="Transformacion Digital">Transformacion Digital</Option>
            <Option value="Economía">Economía</Option>
            <Option value="Talento">Talento</Option>
            <Option value="Trabajo Híbrido">Trabajo Híbrido</Option>
            <Option value="Negociación">Negociación</Option>
            <Option value="Educación">Educación</Option>
            <Option value="Redes sociales">Redes sociales</Option>
            <Option value="Marca Personal">Marca Personal</Option>
            <Option value="Familia y Trabajo">Familia y Trabajo</Option>
            <Option value="Relaciones de Pareja">Relaciones de Pareja</Option>
            <Option value="Paternidad">Paternidad</Option>
            <Option value="Maternidad">Maternidad</Option>
            <Option value="Emprendimiento">Emprendimiento</Option>
            <Option value="Startups">Startups</Option>
            <Option value="Salud Mental">Salud Mental</Option>
            <Option value="Arte">Arte</Option>
            <Option value="Empleo">Empleo</Option>
            <Option value="Adicciones">Adicciones</Option>
            <Option value="Teletrabajo">Teletrabajo</Option>
            <Option value="Inteligencia Artificial">Inteligencia Artificial</Option>
            <Option value="Innovación">Innovación</Option>
            <Option value="Marketing Digital">Marketing Digital</Option>
            <Option value="Comunidad">Comunidad</Option>
            <Option value="Equipo">Equipo</Option>
            <Option value="Vocería">Vocería</Option>
            <Option value="Presentaciones">Presentaciones</Option>
            <Option value="Experiencia">Experiencia</Option>
            <Option value="Pensamiento Crítico">Pensamiento Crítico</Option>
            <Option value="Diversidad">Diversidad</Option>
            <Option value="Seguridad">Seguridad</Option>
            <Option value="Relaciones afectivas">Relaciones afectivas</Option>
            <Option value="Igualdad">Igualdad</Option>
            <Option value="Religión">Religión</Option>
            <Option value="Cultura">Cultura</Option>
            <Option value="Estereotipos">Estereotipos</Option>
            <Option value="Política">Política</Option>
            <Option value="Libertad de expresión">Libertad de expresión</Option>
            <Option value="Salud Física">Salud Física</Option>
            <Option value="Vacaciones">Vacaciones</Option>
            <Option value="Viajes">Viajes</Option>
            <Option value="Otro">Otro</Option>
          </Select>
        </form>

        <span className="flex items-center justify-center w-full bg-Gray-1 pb-2">
          <Button className="text-center text-xs" onClick={resetFilters}>Borrar Filtros</Button>
        </span>
      </div>
      <div className="flex flex-wrap p-5 gap-5 justify-center ">
        {usersData
          .filter((yiyer) => {
            const fullName = yiyer.nombre + " " + yiyer.apellido;
            const matchesSearch = fullName.toLowerCase().includes(searchValue.toLowerCase());
            const categoriesArray = parseCategoryString(yiyer.categoria);
            /* console.log(`Comparing selectedCategory: ${selectedCategory} with categoriesArray: ${categoriesArray}`); */
            const matchesCategory = selectedCategory ? categoriesArray.includes(selectedCategory) : true;
            return matchesSearch && matchesCategory;
          })
          .slice(firstIndex, lastIndex)
          .map((yiyer) => {
            return (
              <CardYiyersHorizontal
                key={Math.random() + yiyer.id}
                id={yiyer.id}
                picture={yiyer.imgurl}
                name={yiyer.nombre}
                lastname={yiyer.apellido}
                intro={yiyer.intro}
                profesion={yiyer.profesion}
                horarios={yiyer.horarios}
                categoria={yiyer.categoria}
                linkedin={yiyer.linkedin}
                experiencia={yiyer.experiencia}
              />
            );
          })}
      </div>
      <div className="flex justify-center p-10">
        <Paginacion
          yiyersPerPage={yiyersPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalYiyers={totalYiyers}
        />
      </div>
    </section>
  );
};

export default Comunidad;
