import React from 'react'
import { Collapse } from "@material-tailwind/react";

function MensajeErrorForms({texto, open}) {
  return (
    <Collapse open={open} className="flex flex-row justify-center align-middle w-full bg-amber-50 text-orange-700 text-sm text-center font-outfit p-2 rounded-lg opacity-70 border-orange-800 border-2 my-3 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="flex w-5 h-5 self-start align-top"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            {texto}
          </Collapse>
  )
}

export default MensajeErrorForms