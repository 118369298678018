import React from 'react'
import imagesApp from "../assets/images/staticimgs";
 

const ComoFunciona = () => {
  return (
    <>
    {/* 1 */}
    <section className="bg-Leopard-3 flex flex-col justify-center items-center w-screen sm:flex-row p-10 sm:px-20 space-y-8 sm:space-x-8 text-center sm:text-start relative z-0">
        <article className="flex flex-col basis-1/2 lg:max-w-xl space-y-5">
          <h1 className=" w-fit px-4 py-1 text-3xl font-extrabold border-4 border-Leopard-0 text-Leopard-0 rounded-full  self-center sm:self-start">
            1
          </h1>
          <h1 className="w-fit text-2xl font-extrabold  text-Leopard-0 rounded-full ">
          Escoge tu área de interés
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet
            luctus elit a tristique. In vitae maximus mi, tincidunt dictum
            neque. Fusce suscipit, enim ut vulputate finibus, tortor felis
            pellentesque elit, porta iaculis nisi risus non nunc. Praesent sit
            amet lorem ligula.{" "}
          </p>
        </article>

        <img
          src={imagesApp.HowWorks_01}
          alt="imagen de videollamada"
          className="object-contain rounded-2xl basis-1/2 w-full max-w-xl sm:w-1/2 lg:max-w-md"
          loading="lazy"
        />
      </section>

    {/* 2 */}
    <section className="bg-Shark-3 flex flex-col gap-4 justify-center items-center w-screen sm:flex-row p-10 sm:px-20 space-y-8 sm:space-x-8 text-center sm:text-start relative z-0">
        <img
          src={imagesApp.HowWorks_02}
          alt="imagen de videollamada"
          className="object-contain rounded-2xl basis-1/2 w-full max-w-xl sm:w-1/2 lg:max-w-md order-2 sm:order-1"
          loading="lazy"
        />
        
        <article className="flex flex-col basis-1/2 lg:max-w-xl space-y-5 order-1 sm:order-2">
          <h1 className="w-fit px-4 py-2 text-3xl font-extrabold border-4 border-Shark-0 text-Shark-0 rounded-full self-center sm:self-start">
            2
          </h1>
          <h1 className="w-fit text-2xl font-extrabold  text-Shark-0 rounded-full ">
          Escoge al Yiyer que más se ajuste a tus necesidades
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet
            luctus elit a tristique. In vitae maximus mi, tincidunt dictum
            neque. Fusce suscipit, enim ut vulputate finibus, tortor felis
            pellentesque elit, porta iaculis nisi risus non nunc. Praesent sit
            amet lorem ligula.{" "}
          </p>
        </article>
      </section>

    {/* 3 */}
    <section className="bg-Flamingo-3 flex flex-col justify-center items-center w-screen sm:flex-row p-10 sm:px-20 space-y-8 sm:space-x-8 text-center sm:text-start relative z-0">
        <article className="flex flex-col basis-1/2 lg:max-w-xl space-y-5">
          <h1 className=" w-fit px-3 py-1 text-3xl font-extrabold border-4 border-Flamingo-0 text-Flamingo-0 rounded-full  self-center sm:self-start">
            3
          </h1>
          <h1 className="w-fit text-2xl font-extrabold  text-Flamingo-0 rounded-full ">
          Agenda una sesión con tu Yiyer
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet
            luctus elit a tristique. In vitae maximus mi, tincidunt dictum
            neque. Fusce suscipit, enim ut vulputate finibus, tortor felis
            pellentesque elit, porta iaculis nisi risus non nunc. Praesent sit
            amet lorem ligula.{" "}
          </p>
        </article>

        <img
          src={imagesApp.HowWorks_03}
          alt="imagen de videollamada"
          className="object-contain rounded-2xl basis-1/2 w-full max-w-xl sm:w-1/2 lg:max-w-md"
          loading="lazy"
        />
      </section>

    {/* 4 */}
    <section className="bg-Bunting-3 flex flex-col gap-4 justify-center items-center w-screen sm:flex-row p-10 sm:px-20 space-y-8 sm:space-x-8 text-center sm:text-start relative z-0">
        <img
          src={imagesApp.HowWorks_04}
          alt="imagen de videollamada"
          className="object-contain rounded-2xl basis-1/2 w-full max-w-xl sm:w-1/2 lg:max-w-md order-2 sm:order-1"
          loading="lazy"
        />
        
        <article className="flex flex-col basis-1/2 lg:max-w-xl space-y-5 order-1 sm:order-2">
          <h1 className="w-fit px-4 py-2 text-3xl font-extrabold border-4 border-Bunting-0 text-Bunting-0 rounded-full self-center sm:self-start">
            4
          </h1>
          <h1 className="w-fit text-2xl font-extrabold  text-Bunting-0 rounded-full ">
          Aprende todo lo que puedas
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut aliquet
            luctus elit a tristique. In vitae maximus mi, tincidunt dictum
            neque. Fusce suscipit, enim ut vulputate finibus, tortor felis
            pellentesque elit, porta iaculis nisi risus non nunc. Praesent sit
            amet lorem ligula.{" "}
          </p>
        </article>
      </section>      
    </>
  )
}

export default ComoFunciona