import React from 'react'
import { Link } from 'react-router-dom'

function ButtonNavHeader({buttonText, rutaHeader}) {


  return (
    <Link to={rutaHeader} className='hidden sm:inline whitespace-nowrap transition ease-in-out delay-150 p-2 text-xs lg:text-sm rounded-md hover:bg-Flamingo-1 hover:text-White focus:bg-Flamingo-1 focus:text-White' >{buttonText}</Link>
  )
}

export default ButtonNavHeader