import React from "react";
import { Carousel } from "@material-tailwind/react";
import imagesApp from "../../assets/images/staticimgs";
import "../../styles/App.css"

function CarruselHome() {
  return (
    <Carousel
      transition={{ type: "spring", duration: 0.5 }} 
      autoplay={true}
      loop={true}
      navigation={false}
    >
      <div className="relative h-full w-full">
        <img
          src={imagesApp.bg_banner01}
          alt="Carrusel 1"
          className="h-full w-full object-cover"
        />
        
      </div>
      <div className="relative h-full w-full">
        <img
          src={imagesApp.bg_banner02}
          alt="Carrusel 2"
          className="h-full w-full object-cover"
        />

      </div>
      <div className="relative h-full w-full">
        <img
          src={imagesApp.bg_banner03}
          alt="Carrusel 3"
          className="h-full w-full object-cover"
        />
        
      </div>
    </Carousel>
  );
}

export default CarruselHome;
