module.exports = {
    URL_HOST: "https://www.yiyaz.com/yiyazapp",

    /* URL_HOST: "http://localhost:3000", */
    /* URL_HOST: "https://yiyaz-api.onrender.com", */
    
    /* URL_HOST: "https://www.yiyaz.com/yiyazapp" */

    ZOOM_CLIENT_ID: 'dlONiwQQRVOxZXilo6D4gw',
    ZOOM_CLIENT_SECRET: 'vCKrBxWbYokP91xaqI8kY2QnT3JnK8Ic',
}