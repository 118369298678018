import React from 'react'
import LoginForm from '../components/comun/LoginForm'

function Login() {
  return (
    <>
    <LoginForm />
    </>
  )
}

export default Login