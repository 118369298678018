import React, { useEffect, useState } from "react";
import { Dropdown } from "flowbite-react";
import LogoutButton from "../comun/LogoutButton";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { useAuth } from "../../context/AuthContext";
import io from "socket.io-client";

function LoguedButtonHeader() {
  const [user, setUser] = useState(null); // Cambiado a null
  const { isAuthenticated } = useAuth();
  const storedUserData = localStorage.getItem("userData");

  useEffect(() => {
    setUser(JSON.parse(storedUserData));
  }, [storedUserData]);

  const navigate = useNavigate();
  const id = user ? user.id : null; // Verificando si user es nulo antes de acceder a id

  return (
    <div className={`flex ${isAuthenticated ? "" : "sm:hidden"}`}>
      <Dropdown
        color={"dark"}
        label={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        }
      >
        {isAuthenticated ? (
          <Dropdown.Header>
            {user && (
              <>
                <span className="block text-sm">
                  {user.nombre} {user.apellido}
                </span>
                <span className="block truncate text-sm font-medium">
                  {user.email}
                </span>
              </>
            )}
          </Dropdown.Header>
        ) : null}

        {isAuthenticated ? (
          <>
            <Dropdown.Item onClick={() => {
              if(user.rol === 'Yiyer' || user.rol === 'yiyer'){
                navigate("/miperfil")
              } else if(user.rol === 'yiyu'){
                navigate("/yiyuprofile")
              }
              
              }}>
              <span className="text-sm inline-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 mx-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  />
                </svg>
                Mi perfil{" "}
              </span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {
              if(user.rol === 'Yiyer' || user.rol === 'yiyer'){
                navigate(`/agendayiyer/${id}`)
              } else if(user.rol === 'yiyu'){
                navigate(`/agendayiyu/${id}`)
              }
              
              }}>
              <span className="text-sm inline-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 mx-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                  />
                </svg>
                Mi Agenda
              </span>
            </Dropdown.Item>
            <Dropdown.Divider />

            <Dropdown.Item>
              {" "}
              <LogoutButton />
            </Dropdown.Item>
          </>
        ) : (
          <>
            <Dropdown.Item onClick={() => navigate("/")}>
              <span className="text-sm inline-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 mx-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                Inicio{" "}
              </span>
            </Dropdown.Item>

            <Dropdown.Item onClick={() => navigate("/comunidad")}>
              <span className="text-sm inline-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 mx-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                  />
                </svg>
                Buscar Yiyer{" "}
              </span>
            </Dropdown.Item>

            <Dropdown.Item onClick={() => navigate("/about")}>
              <span className="text-sm inline-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 mx-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
                Sobre Yiyaz{" "}
              </span>
            </Dropdown.Item>

            <Dropdown.Item onClick={() => navigate("/#comofunciona")}>
              <span className="text-sm inline-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 mx-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
                Cómo Funciona{" "}
              </span>
            </Dropdown.Item>
            <Dropdown.Item className="flex flex-col gap-2">
              <Button onClick={() => navigate("/login")}>Iniciar Sesión</Button>
              <Button variant="outlined" onClick={() => navigate("/registro")}>Registrarse</Button>
            </Dropdown.Item>
          </>
        )}
      </Dropdown>
    </div>
  );
}

export default LoguedButtonHeader;
