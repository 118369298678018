import axios from "axios";
import React, { useState } from "react";
import config from "../../util/config";
import MensajeErrorForms from "./MensajeErrorForms";
import CardYiyerMuestraSignUp from "./CardYiyerMuestraSignUp";
import { Input, Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

function FormRegistroYiyu() {
  const [errorRegister, setErrorRegister] = useState(false);
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [passVisible, setPassVisible] = useState(false);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    nombre: "",
    apellido: "",
    email: "",
    pais: "",
    profesion: "",
    rol: "yiyu",
    foto: null,
    password: "",
  });

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value || "" }));
    setErrorRegister(false);
  };

  const handleFileChange = (e) => {
    setForm({ ...form, foto: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nombre", form.nombre);
    formData.append("apellido", form.apellido);
    formData.append("email", form.email);
    formData.append("pais", form.pais);
    formData.append("profesion", form.profesion);
    formData.append("rol", form.rol);
    formData.append("password", form.password);
    formData.append("foto", form.foto);

    try {
      // eslint-disable-next-line
      const response = await axios.post(
        `${config.URL_HOST}/registroyiyu`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      /* console.log('Respuesta del servidor:', response.data); */
      setRegistroExitoso(true);
    } catch (error) {
      if (error.response.data.code === "23505") {
        setErrorText("El email ya está registrado");
        setErrorRegister(true);
      }
    }
  };

  return (
    <section className="flex flex-row justify-center align-middle p-10 w-full bg-Gray-1 font-outfit gap-4">
      {!registroExitoso ? (
        <>
          <CardYiyerMuestraSignUp />
          <form
            onSubmit={handleSubmit}
            method="POST"
            encType="multipart/form-data"
            className="flex flex-col gap-3 bg-white p-8 rounded-xl shadow-md min-w-[400px] w-full md:w-10/12"
          >
            {errorRegister ? (
              <MensajeErrorForms texto={errorText} open={errorRegister} />
            ) : null}
            <h2 className="text-2xl font-bold mb-5 text-orange-400">
              Conviertete en un Yiyu
            </h2>
            <div className="flex flex-col md:flex-row gap-3">
              <Input
                label="Nombre"
                name="nombre"
                onChange={handleChange}
                value={form.nombre}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                required
              />
              <Input
                label="Apellido"
                name="apellido"
                onChange={handleChange}
                value={form.apellido}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-3">
              <Input
                label="Email"
                name="email"
                onChange={handleChange}
                value={form.email}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                autoComplete="email"
                required
              />
              <Input
                label="País"
                name="pais"
                onChange={handleChange}
                value={form.pais}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                required
              />
            </div>

            <div className="flex flex-col md:flex-row gap-3">
              <Input
                label="Profesión u oficio"
                name="profesion"
                onChange={handleChange}
                value={form.profesion}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                required
              />
            </div>

            <Input
              label="Imagen de perfil"
              size="lg"
              type="file"
              name="foto"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full mb-3 py-2 px-3 "
              required
            />

            <div className="relative flex w-full">
              <Input
                label="Contraseña"
                name="password"
                type={passVisible ? "text" : "password"}
                onChange={handleChange}
                value={form.password}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                required
              />
              <Button
              variant="text"
                onClick={() => setPassVisible(!passVisible)}
                size="sm"
                disabled={!form.password}
                className={
                  passVisible
                    ? "!absolute bg-transparent right-1 top-1 rounded text-gray-500 text-lg"
                    : "!absolute bg-transparent right-1 top-1 rounded text-gray-500 text-lg"
                }
              >
                {passVisible ? <FaRegEyeSlash /> : <FaRegEye />}
              </Button>
            </div>

            <input
              type="submit"
              value="Resgistrarse"
              className="block w-full bg-orange-500 hover:bg-orange-400 p-2 rounded text-white font-bold cursor-pointer"
            />
          </form>
        </>
      ) : (
        <div className="flex flex-col justify-center align-middle text-center gap-3 bg-white p-8 rounded-xl shadow-md w-full">
          <h1 className="text-3xl font-extrabold text-Flamingo-0">
            Felicidades
          </h1>
          <p className="text-lg text-gray-900">!Ya eres un Yiyu¡</p>
          <Button onClick={() => navigate("/login")}>Iniciar Sesión</Button>
        </div>
      )}
    </section>
  );
}

export default FormRegistroYiyu;
