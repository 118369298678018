import React, { useState } from 'react';
import config from "../../util/config"

const ExcelForm = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Aquí debes implementar la lógica para enviar el archivo al backend
    // y procesar los datos en tu ruta correspondiente

    const formData = new FormData();
    formData.append('excelFile', file);

    try {
      const response = await fetch(`${config.URL_HOST}/registroyiyumasivo`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('Datos guardados exitosamente');
      } else {
        console.error('Error al guardar datos');
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };

  return (
    <div className="container mx-auto mt-8">
      <form onSubmit={handleFormSubmit} className="max-w-md mx-auto bg-white p-8 shadow-md rounded">
        <h2 className="text-2xl font-semibold mb-6">Cargar Excel</h2>

        <div className="mb-4">
          <label htmlFor="excelFile" className="block text-gray-700 text-sm font-bold mb-2">
            Seleccionar Archivo Excel:
          </label>
          <input
            type="file"
            id="excelFile"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            className="border rounded w-full py-2 px-3"
          />
        </div>

        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
          Enviar Datos
        </button>
      </form>
    </div>
  );
};

export default ExcelForm;
