import React from 'react'
import imagesApp from '../../assets/images/staticimgs'

function CardYiyerMuestraSignUp() {
  return (
    <div className="hidden lg:flex flex-col justify-start items-center w-8/12 sm:w-60 text-sm space-y-3 p-4 bg-White shadow-md rounded-xl" >
        <div><p className=' text-lg underline uppercase'>Perfil de ejemplo:</p></div>
      <img src={imagesApp.profileIcon} alt="Foto de perfil" className=' w-36 mx-auto rounded-xl'/>
        
      <h1 className="text-lg font-bold text-center leading-3 mt-6">Juan Perez</h1>
      <h1 className="text-md font-bold text-center leading-3 text-gray-700 mt-6">Gerente de Marketing</h1>
      <p className='text-sm text-blue-gray-600 leading-3'>usuario@email.com</p>
      <p className='text-sm text-blue-gray-400 leading-3'>Chile</p>
      <h1 className=' bltext-md font-extrabold underline mt-6'>INTRODUCCIÓN</h1>
      <p className="text-start text-sm">
      Considero que es posible efectuar cambios significativos en las estructuras empresariales mediante acciones discretas, basándome en tres décadas de inmersión en el mundo de las juntas directivas y en mi rol de liderazgo en corporaciones de gran envergadura. 
      </p>
      <h1 className='text-md font-extrabold underline text-center'>TEMATICAS QUE QUIERO RESOLVER</h1>
      <div className='flex flex-wrap gap-2'>
      <span className='block p-2 text-xs bg-blue-gray-100 text-blue-gray-900 rounded-md'>Cómo pedir un aumento de sueldo</span>
      <span className='block p-2 text-xs bg-blue-gray-100 text-blue-gray-900 rounded-md'>Marketing</span>
      <span className='block p-2 text-xs bg-blue-gray-100 text-blue-gray-900 rounded-md'>Liderazgo</span>
      </div>
      {/* <button type="button" className='bg-Black w-11/12 p-2 rounded-md text-White'>Click Me!</button> */}
    </div>
  )
}

export default CardYiyerMuestraSignUp