import React, { useContext, useEffect } from "react";
import { Avatar } from "@material-tailwind/react";
import { ContextUser } from "../../context/ContextUser";
import { useNavigate } from "react-router-dom";
import imagesApp from "../../assets/images/staticimgs";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import config from "../../util/config";

function MyProfileYiyu() {
  const { yiyerData, setYiyerData } = useContext(ContextUser);
  const { token } = useAuth();


  useEffect(() => {
    if (token) {
      axios
        .get(`${config.URL_HOST}/yiyuprofile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setYiyerData(response.data);
        })
        .catch((error) => {
          console.error("Error al obtener los datos del usuario:", error);
        });
    }
  }, [token]);

  const replaceImageUrl = (url) => {
    return url.replace("/home/yiyazcom/public_html", "https://www.yiyaz.com");
  };

  return (
    <section className="flex flex-col sm:flex-row justify-center align-middle w-full bg-gray-50 p-4 font-outfit">
      {yiyerData && (
        <>
          <div className="flex flex-col w-4/5 md:w-3/5 lg:w-2/5 p-5 bg-white rounded-xl shadow-xl mx-auto min-w-[340px] gap-3">
            {/* HEADER */}
            <div className="flex flex-row gap-5 ">
              {yiyerData.imgurl ? (
                <Avatar
                  src={replaceImageUrl(yiyerData.imgurl)}
                  alt="avatar"
                  size="xxl"
                  loading="lazy"
                  onError={(e) => {
                    e.target.src = imagesApp.noImg;
                  }}
                />
              ) : (
                // Si no se carga ninguna imagen, muestra una imagen por defecto
                <img
                  src={imagesApp.noImg}
                  alt="avatar"
                  className="w-20 h-20 rounded-full"
                />
              )}
              <div className="flex flex-col justify-center">
                <h1 className="font-black text-xl">
                  {yiyerData.nombre} {yiyerData.apellido}
                </h1>
                <p className="text-gray-600 text-sm">{yiyerData.email}</p>
              </div>
            </div>
            {/* END HEADER */}

            <div className="flex flex-col w-full mt-4 py-5">

            </div>
            
          </div>

        </>
      )}
    </section>
  );
}

export default MyProfileYiyu;
